import React from "react";
import { graphql } from 'gatsby'
import ReactPaginate from 'react-paginate';
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import BlogCategory from "../components/blog/home/blogCategory";
import { getSafe } from "../utils/common";
import { Pagination } from "../utils/pagination";
import Layout from "../layouts";

export class BlogByTagPage extends React.Component {
  constructor(props) {
    super(props);
    let {
      data: {
        allDatoCmsBlogPost
      }
    } = props;
    let blogs = getSafe(() => allDatoCmsBlogPost.edges);
    let blogPaging = new Pagination(blogs, 21);

    this.state = {
      blogPaging,
      data: blogPaging.pageChanged()
    };

  }

  handlePageClick = data => {
    let page = data.selected + 1;
    this.setState({
      data: this.state.blogPaging.pageChanged(page)
    })
  };
  
  render() {
    let {
      data: {
        datoCmsBlogHome,        
        allDatoCmsBlogTag: { edges: tags },
        allDatoCmsNotification: { edges: notifications }
      }
    } = this.props;

    let tagName = getSafe(() => tags[0].node.tagName);

    return (
      <Layout>
        <Notification notifications={notifications} />
        <HelmetDatoCms seo={datoCmsBlogHome.seoMetaTags} />
        <header className="main-header about">
          <div className="container grid">
          <BlogCategory data={this.state.data} category={tagName} />
          {this.state.blogPaging.isShowPagination &&  
              <ReactPaginate                
                previousLabel={''}
                nextLabel={''}      
                pageClassName={'page-number'}  
                breakLabel={'...'} 
                breakClassName={'page-number'}
                pageCount={this.state.blogPaging.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            }
          </div>
        </header>
      </Layout>
    );
  }

};

export default BlogByTagPage;

export const query = graphql`
  query BlogsByTag($slug: String!) {
    datoCmsBlogHome {
      id
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
    allDatoCmsBlogPost(
      filter: {tags: { elemMatch: {slug: {eq: $slug}}}}      
      sort: { fields: [publishedOn], order: DESC }
    ) {
      edges {
        node {
          id
          slug
          blogTitle
          publishedOn(formatString: "MMM D, YYYY")
          featureImage {
            url
          }
          author {
            authorImage {
              url
            }
            authorName
          }
          category {
            categoryName
            description
          }
        }
      }
    }
    allDatoCmsBlogTag(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          tagName
          slug
        }
      }
    }
  }
`;
